<template>
  <div>
    <div class="containerBox">
      <div class="bannerContent">
        <div
          class="bannerInner"
          :style="{ 'background-image': 'url(' + bannerImg + ')' }"
        >
          <div class="enterBtn tc cursor" @click="toEnterLive">
            {{ $t("enterLive") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content_area tr refresh">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        size="small"
        @click="refreshList"
        >{{ $t("refresh") }}</el-button
      >
    </div>
    <wonderful :isAll="true" ref="wonderful"></wonderful>
  </div>
</template>
<script>
import wonderful from "~exp/components/home/wonderful";

export default {
  components: {
    wonderful,
  },
  data() {
    return {
      bannerImgzh:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/newAdd/lADPD4BhtJs1WXTNAV7NB4A_1920_350.jpg",
      bannerImgen:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/newAdd/lADPD3IruggZkQ_NAV7NB4A_1920_350.jpg",
    };
  },
  methods: {
    toEnterLive() {
      window.open("https://live.polyv.cn/watch/2254442");
    },
    refreshList() {
      this.$refs.wonderful.getList();
    },
  },
  computed: {
    bannerImg() {
      return this.LOCALE == "en" ? this.bannerImgen : this.bannerImgzh;
    },
  },
};
</script>
<style lang="less" scoped>
.containerBox {
  width: 100%;
  padding-top: 18.23%;
  position: relative;
  margin-bottom: 30px;
  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;
    }
  }
  .img {
    width: 100%;
  }
  .enterBtn {
    height: 40px;
    padding: 0 3.5%;
    font-size: 16px;
    color: rgb(0, 69, 214);
    background: #fff;
    position: absolute;
    left: 50%;
    bottom: 10%;
    line-height: 35px;
    transform: translateX(-50%);
  }
}
.refresh {
  margin-bottom: 16px;
  padding-right: 10px;
}
</style>