<template>
  <div>
    <div class="content_area" v-loading="loading">
      <div class="imageDiv clearfix">
        <al-image
          v-for="(it, index) in liveResImg"
          :key="index"
          fit="cover"
          class="liveImg fl"
          :preview-src-list="liveResImg"
          :src="it"
        ></al-image>
      </div>
      <div class="footer tc" v-if="total >= 16 && isAll">
        <el-button
          class="morebut"
          size="small"
          @click="getMore"
          :loading="moreLoading"
          v-if="!noMore"
          >{{ $t("loadMore") }}
        </el-button>
        <el-button class="morebut" size="small" v-else>
          {{ $t("noMore") }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      liveResData: [],
      liveResImg: [],
      noMore: false,
      loading: false,
      moreLoading: false,
      total: 0,
    };
  },
  methods: {
    async getList(id) {
      this.loading = true;
      this.noMore = false;
      try {
        let nowTime = new Date().getTime();
        // console.log(nowTime);
        let params = {
          channel_id: 2254442,
          timestamp: nowTime,
        };
        if (id) {
          params.id = id;
        }
        let result = await this.$store.dispatch(
          "baseStore/getPolyvliveImageSign",
          params
        );
        if (result.success) {
          let liveParams = {
            appId: "fkyterpju6",
            channelId: params.channel_id,
            timestamp: nowTime,
            sign: result.data,
            imageMode: "Y",
          };
          if (id) {
            liveParams.id = id;
          }
          let liveRes = await this.$store.dispatch(
            "baseStore/getPolyvliveImage",
            liveParams
          );
          if (liveRes.status == "success") {
            let image = [];
            liveRes.data.contents.forEach((it) => {
              it.images.forEach((item) => {
                image.push(item);
              });
            });
            if (image.length == 0 && !id) {
              this.$emit("isWonderfulHide");
            }
            if (image.length < 16 || image.length == 0) {
              this.noMore = true;
            }
            if (id) {
              this.liveResData = this.liveResData.concat(liveRes.data.contents);
            } else {
              this.total = image.length;
              this.liveResData = liveRes.data.contents;
            }
            let images = [];
            this.liveResData.forEach((it) => {
              it.images.forEach((item) => {
                images.push(item);
              });
            });
            if (this.isAll) {
              this.liveResImg = images;
            } else {
              this.liveResImg = images.splice(0, 8);
            }
          }
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    getMore() {
      let length = this.liveResData.length;
      let id = this.liveResData[length - 1].id;
      this.getList(id);
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.imageDiv {
  min-height: 400px;
  padding-bottom: 30px;
}
.liveImg {
  width: 300px;
  height: 220px;
  padding: 10px;
  margin-left: 33px;
  margin-bottom: 16px;
  &:nth-child(4n + 1) {
    margin-left: 0;
  }
}
.morebut {
  margin-bottom: 30px;
}
</style>